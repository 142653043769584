import React from "react"
import { techStackDetails } from "../Details"
const Tools = () => {
  const { vscode, git, github, npm, postman } = techStackDetails
  return (
    <>
      <section>
        <h1 className="text-2xl pt-10 text-dark-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Tools
        </h1>
      </section>
      <section className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-6 items-center gap-10 pt-6">
        <img src={vscode} title="Visual Studio Code" alt="" />
        <img src={git} title="Git" alt="Git" />
        <img src={github} title="Github" alt="Github" />
        <img src={npm} title="NPM" alt="NPM" />
        <img src={postman} title="Postman" alt="Postman" />
      </section>
    </>
  )
}

export default Tools
