import React from "react"
import { techStackDetails } from "../Details"

function TechStack() {
  const {
    html,
    css,
    js,
    react,
    redux,
    tailwind,
    bootstrap,
    python,
    firebase,
    nodejs,
    mongodb,
  } = techStackDetails
  return (
    <>
      <section>
        <h1 className="text-2xl text-dark-heading  md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Tech Stack
        </h1>
      </section>
      <section className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-6 items-center gap-10 pt-6">
        <img src={html} title="Html" alt="Html" />
        <img src={css} title="CSS" alt="CSS" />
        <img src={js} title="JavaScript" alt="JavaScript" />
        <img src={react} title="React" alt="React" />
        <img src={redux} title="Redux" alt="Redux" />
        <img src={nodejs} title="NodeJs" alt="NodeJs" />
        <img src={python} title="Python" alt="Python" />
        <img src={mongodb} title="MongoDb" alt="MongoDb" />
        <img src={firebase} title="Firebase" alt="Firebase" />
        <img src={tailwind} title="Tailwind CSS" alt="Tailwind CSS" />
        <img src={bootstrap} title="Bootstrap" alt="Bootstrap" />
      </section>
    </>
  )
}

export default TechStack
