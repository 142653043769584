import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBuilding,
  faLocationDot,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons"

function Work({ position, company, location, type, duration, companyLogo }) {
  return (
    <article
      style={{ height: "120px" }}
      className="pt-8 border-b-2 border-dark-content pb-1 border-opacity-20  flex"
    >
      <div className="flex-1">
        <div>
          <h1 className="text-content md:text-lg lg:text-xl">{position}</h1>
        </div>
        <div className="flex pt-3">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faBuilding} />
            <p className="text-content text-xs md:text-sm font-light pl-1">
              {company}
            </p>
          </div>
          <div className="flex items-center pl-3">
            <FontAwesomeIcon icon={faLocationDot} />
            <p className="text-content text-xs md:text-sm font-light pl-1">
              {location}
            </p>
          </div>
          <div className="flex items-center pl-3">
            <FontAwesomeIcon icon={faCalendar} />
            <p className="text-content text-xs md:text-sm font-light pl-1 min-w-fit">
              {duration}
            </p>
          </div>
        </div>
      </div>
      <div style={{ flex: "0 0 10%" }}>
        <img
          src={companyLogo}
          alt={company}
          style={{ width: "100%", height: "100%" }}
          class="hidden md:block"
        />
      </div>
    </article>
  )
}

export default Work
