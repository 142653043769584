import React from "react"
function Footer() {
  return (
    <footer className="container mx-auto py-1 fixed bottom-0 md:left-20 bg-white ">
      <p className="text-xs text-center text-dark-content  w-full">
        Coded by Alex Truong
      </p>
    </footer>
  )
}
export default Footer
