import React from "react"
import Project from "../Components/Project"
import { projectDetails } from "../Details"

function Projects() {
  return (
    <main className="container mx-auto max-width pt-10 mb-20">
      <section>
        <h1 className="text-2xl text-dark-heading  md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Projects
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-10">
          {projectDetails.map(
            ({
              title,
              images,
              description,
              techstack,
              previewLink,
              githubLink,
            }) => (
              <Project
                key={title} // Assuming title is unique, use a better unique identifier if possible
                title={title}
                images={images}
                description={description}
                techstack={techstack}
                previewLink={previewLink}
                githubLink={githubLink}
              />
            )
          )}
        </div>
      </section>
    </main>
  )
}

export default Projects
