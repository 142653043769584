import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./project.css"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLink } from "@fortawesome/free-solid-svg-icons"
import { faGithub } from "@fortawesome/free-brands-svg-icons"
function Project({
  title,
  images,
  description,
  techstack,
  previewLink,
  githubLink,
}) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <article className="rounded-xl mt-10 overflow-hidden shadow-xl shadow-slate-300 ">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Project ${index + 1}`} loading="lazy" />
          </div>
        ))}
      </Slider>
      <div className=" p-4 cardContainer">
        <h1 className=" font-semibold text-xl pt-1">{title}</h1>
        <p className="text-content pt-4 font-light">{description}</p>
        <h3 className="text-dark-heading  font-medium pt-4">
          Tech Stack : <span className="font-light">{techstack}</span>
        </h3>
        <div className=" flex justify-between items-center mt-5 links">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faLink} />
            <a
              href={previewLink}
              target="_blank"
              rel="noreferrer noopener"
              className="underline pl-2 font-light "
            >
              Live Preview
            </a>
          </div>
          <div className="flex items-center">
            <FontAwesomeIcon icon={faGithub} />
            <a
              href={githubLink}
              target="_blank"
              rel="noreferrer noopener"
              className="underline pl-2 font-light"
            >
              View Code
            </a>
          </div>
        </div>
      </div>
    </article>
  )
}

export default Project
