import React from "react"
import Work from "../Components/Work"
import { workDetails, eduDetails } from "../Details"
import TechStack from "../Components/TechStack"
import Education from "../Components/Education"
import Tools from "../Components/Tools"

function Experience() {
  return (
    <>
      <main className="container mx-auto max-width pt-10 pb-10">
        <section>
          <h1 className="text-2xl text-dark-heading  md:text-4xl xl:text-5xl xl:leading-tight font-bold">
            Work Experience
          </h1>
          {workDetails.map(
            ({ Position, Company, Location, Type, Duration, CompanyLogo }) => (
              <Work
                key={`${Company}-${Position}`} // Make sure to include a unique key
                position={Position}
                company={Company}
                location={Location}
                type={Type}
                duration={Duration}
                companyLogo={CompanyLogo}
              />
            )
          )}
        </section>
        <section>
          <h1 className="text-2xl pt-10 text-dark-heading  md:text-4xl xl:text-5xl xl:leading-tight font-bold">
            Education
          </h1>
          {eduDetails.map(
            ({ Position, Company, Location, Type, Duration, SchooLogo }) => (
              <Education
                key={`${Company}-${Position}`} // Make sure to include a unique key
                position={Position}
                company={Company}
                type={Type}
                duration={Duration}
                schoolLogo={SchooLogo}
              />
            )
          )}
        </section>
      </main>

      <main className="container mx-auto max-width pt-5 pb-20">
        <TechStack />
        <Tools />
      </main>
    </>
  )
}

export default Experience
