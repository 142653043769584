// Enter all your detials in this file
// Logo images

// Profile Image
import profile from "./assets/alex_profile.png"
// Tech stack images
import html from "./assets/techstack/html.png"
import css from "./assets/techstack/css.png"
import js from "./assets/techstack/js.png"
import react from "./assets/techstack/react.png"
import redux from "./assets/techstack/redux.png"
import tailwind from "./assets/techstack/tailwind.png"
import bootstrap from "./assets/techstack/bootstrap.png"
import vscode from "./assets/techstack/vscode.png"
import github from "./assets/techstack/github.png"
import git from "./assets/techstack/git.png"
import npm from "./assets/techstack/npm.png"
import postman from "./assets/techstack/postman.png"
import firebase from "./assets/techstack/firebase.png"
import python from "./assets/techstack/python.png"
import mongodb from "./assets/techstack/mongodb.png"
import nodejs from "./assets/techstack/nodejs.png"

// Job Quest Images
import jobQuestImage1 from "./assets/projects/jobquest/img1.png"
import jobQuestImage2 from "./assets/projects/jobquest/img2.png"
import jobQuestImage3 from "./assets/projects/jobquest/img3.png"
import jobQuestImage4 from "./assets/projects/jobquest/img4.png"
import jobQuestImage5 from "./assets/projects/jobquest/img5.png"

// House Marketplace Images
import housemarketplaceImg1 from "./assets/projects/houseMarketplace/img1.png"
import housemarketplaceImg2 from "./assets/projects/houseMarketplace/img2.png"
import housemarketplaceImg3 from "./assets/projects/houseMarketplace/img3.png"
import housemarketplaceImg4 from "./assets/projects/houseMarketplace/img4.png"
import housemarketplaceImg5 from "./assets/projects/houseMarketplace/img5.png"

// Project Pro Images
import projectProImg1 from "./assets/projects/projectPro/img1.png"
import projectProImg2 from "./assets/projects/projectPro/img2.png"
import projectProImg3 from "./assets/projects/projectPro/img3.png"
import projectProImg4 from "./assets/projects/projectPro/img4.png"

// Summarize Images
import summarizeImg1 from "./assets/projects/summarize/img1.png"
import summarizeImg2 from "./assets/projects/summarize/img2.png"

// School And Work Logos
import spsccLogo from "./assets/logos/spsccLogo.png"
import wguLogo from "./assets/logos/wguLogo.png"
import wesromLogo from "./assets/logos/wesromLogo.png"
import x27Logo from "./assets/logos/X27logo.svg"

// Enter your Personal Details here
export const personalDetails = {
  name: "Alex Truong",
  tagline: "I build things for the web",
  img: profile,
}

// Enter your Social Media URLs here
export const socialMediaUrl = {
  linkedIn: "https://www.linkedin.com/in/alex-truong-8a1a8b120/",
  github: "https://github.com/alextruonglt",
}

// Enter your Work Experience here
export const workDetails = [
  {
    Position: "Full Stack Software Engineer",
    Company: `Wesrom`,
    Location: "Remote",
    Type: "Full Time",
    Duration: "Feb 2022 - Feb 2023",
    CompanyLogo: wesromLogo,
  },
  {
    Position: "Technical Sales Engineer",
    Company: `X27 Marketing`,
    Location: "Remote",
    Type: "Full Time",
    Duration: "Nov 2020 - Feb 2022",
    CompanyLogo: x27Logo,
  },
]

// Enter your Education Details here
export const eduDetails = [
  {
    Position: "Bachelor's Of Science In Computer Science",
    Company: "Western Governors University",
    Duration: "Graduation: July 2023",
    SchooLogo: wguLogo,
  },
  {
    Position: "AAS - Accounting",
    Company: `South Puget Sound Community College`,
    Duration: "Graduation: June 2017",
    SchooLogo: spsccLogo,
  },
]

// Tech Stack and Tools
export const techStackDetails = {
  html: html,
  css: css,
  js: js,
  react: react,
  redux: redux,
  tailwind: tailwind,
  bootstrap: bootstrap,
  vscode: vscode,
  postman: postman,
  npm: npm,
  git: git,
  github: github,
  python: python,
  firebase: firebase,
  mongodb: mongodb,
  nodejs: nodejs,
}

// Enter your Project Details here
export const projectDetails = [
  {
    title: "Job Quest",
    images: [
      jobQuestImage1,
      jobQuestImage2,
      jobQuestImage3,
      jobQuestImage4,
      jobQuestImage5,
    ],
    description: `This is a full-stack MERN application that anyone can use to track and manage job applications. Please note that Onrender spins down the server after 15 minutes in-activity. So the first time you click the link, it may take 2-3 minutes for the link to spin up the application. It's literally re-building the app`,
    techstack: "React, Express, MongoDB, NodeJS",
    previewLink: "https://jobapp-0w74.onrender.com/",
    githubLink: "https://github.com/alextruonglt/jobapp",
  },
  {
    title: "House Marketplace",
    images: [
      housemarketplaceImg1,
      housemarketplaceImg2,
      housemarketplaceImg3,
      housemarketplaceImg4,
      housemarketplaceImg5,
    ],
    description: `This application is a comprehensive platform designed for users to list and browse houses for sale and rent. Leveraging a modern tech stack, this MERN application integrates various features including toast notifications, external APIs, and Google authentication for enhanced user experience and security.`,
    techstack: "React, Firebase, CSS",
    previewLink: "https://housemarketplace-alextruong.netlify.app/",
    githubLink: "https://github.com/alextruonglt/house-marketplace",
  },
  {
    title: "Project Pro",
    images: [projectProImg1, projectProImg2, projectProImg3, projectProImg4],
    description: `Project Pro allows executives to collaborate on high-level milestones and goals.`,
    techstack: "React, Firebase",
    previewLink: "https://projectpromanagement.netlify.app/",
    githubLink: "https://github.com/alextruonglt/projectManagement",
  },
  {
    title: "Summarize",
    images: [summarizeImg1, summarizeImg2],
    description: `Summarize AI uses an AI API to summarize any online article. Simply put in a link, and watch it do it's work`,
    techstack: "React",
    previewLink: "https://summarizeaialex.netlify.app/",
    githubLink: "https://github.com",
  },
]

// Enter your Contact Details here
export const contactDetails = {
  email: "alex@alextruong.io",
  phone: "+1 (360)-464-3667",
}
